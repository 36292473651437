import React, { useEffect } from 'react';
import "./TikTok.css";

const TikTokbnb = () => {

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://www.tiktok.com/embed.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="tiktok-section">
      <h1 className="heading-tik">Follow us on <span className='tik-sp'>socials</span> for cleaning <br/>tips and hacks</h1>
      <div className="tiktok-container">
        <blockquote 
          className="tiktok-embed" 
       
          cite="https://www.tiktok.com/@shaun.sprucely2/video/7422913183062527239" 
          data-video-id="7422913183062527239"
        >
          <section>
            <a href="https://www.tiktok.com/@shaun.sprucely2/video/7422913183062527239">@shaun.sprucely2</a>
          </section>
        </blockquote>

        <blockquote 
          className="tiktok-embed" 
         
          cite="https://www.tiktok.com/@shaun.sprucely2/video/7446208965270048018" 
          data-video-id="7446208965270048018"
        >
          <section>
            <a href="https://www.tiktok.com/@shaun.sprucely2/video/7446208965270048018">@anotheruser</a>
          </section>
        </blockquote>

        <blockquote 
          className="tiktok-embed" 
  
          cite="https://www.tiktok.com/@shaun.sprucely2/video/7445195845814783240" 
          data-video-id="7445195845814783240"
        >
          <section>
            <a href="https://www.tiktok.com/@shaun.sprucely2/video/7445195845814783240">@yetanotheruser</a>
          </section>
        </blockquote>
      </div>
    </div>
  )
}

export default TikTokbnb;


