import React, { useEffect } from 'react';
import "./TrippleIcons.css"
import { BsArrowUpCircle } from 'react-icons/bs';
import { BsPersonCircle } from 'react-icons/bs';
import { BsCheckCircle } from 'react-icons/bs';





const TrippleIconsbnb = () => {

    const handleIntersection = (entries, observer) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      entry.target.classList.add('fade-in');
      observer.unobserve(entry.target);
    }
  });
};

useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      rootMargin: '0px',
      threshold: 0.3,
    });
    const container = document.querySelector('.tripple-icons-container');
    observer.observe(container);

    return () => {
      observer.unobserve(container);
    };
  }, []);


  return (
    <div className='icons-main-wrapper'>
    <div className="tripple-icons-container">
        <div className="icons-wrapper">
            <div className="icons-boxes">
            <BsArrowUpCircle color="#1725a" size={140}/>
            <h3 className="icons-heading">Build custom quote</h3>
            <p className="icons-p">Fill in your details below<br/>and receive custom quote via email.</p>
            </div>
            <div className="icons-boxes">
            <BsPersonCircle color="#1725a" size={140}/>
            <h3 className="icons-heading">Follow up call</h3>
            <p className="icons-p">We will touch base to withing 24hrs<br/> to further discuss your needs.</p>
            </div>
            <div className="icons-boxes">
           <BsCheckCircle color="#1725a" size={140}/>
            <h3 className="icons-heading">Confirmed booking</h3>
            <p className="icons-p">Once booking is confirmed we will,<br/> allocate a cleaner for your clean date.</p>
        </div>   
    </div>
  
    </div>
   
    </div>
  )
}

export default TrippleIconsbnb