import { Helmet } from "react-helmet"
import "./BookingClean.css";
import HeaderSteam from "../components/Header";
import Footer from "../components/Footer";
import Profile from "../components/Profile";
import ServiceInfoSection from "../components/ServiceInfoSectionHome";
import { IoMdStar } from "react-icons/io";
import icon from "../assets/iconG.png"
import { Link } from "react-router-dom";
import WorkSlidesRevGen from "../components/WorkSlidesRevGen";
import EndOfLeaseTop from "../components/EndOfLeaseTop";
import EndLeaseThree from "../components/EndLeaseThree";
import ReviewSection from "../components/ReviewSection";
import EndLeaseTwo from "../components/EndLeaseTwo";
import Endofleaseblurb from "../components/Endofleaseblurb";
import EndOfLeaseCleaningPrices from "../components/EndOfLeaseCleaningPrices";
import EndLeaseFour from "../components/EndLeaseFour";
import ServiceInfoSectionEnd from "../components/ServiceInfoSectionEnd";
import ReviewEnd from "../components/ReviewEnd";
import FAQ from "../components/Faq";
import Location from "../components/Location";
import Tiktok from "../components/Tiktok";




const EndOfLeaseCleanMelb = () => {


  return (
    <div>
    <Helmet>
          <meta name="description" content="We offer general and end of lease cleaning service, from carpet steam cleaning through to cleaning."/>
          <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11025934865"></script>
        </Helmet>
            <HeaderSteam/>
            <EndOfLeaseTop/>
       
            <div className="rev-sec">
            <ReviewSection/>
            </div>

            <div className="steam-booking-homepage mar-rev-steam">
            <Link style={{textDecoration: 'none', color: 'inherit', }}  to="/end-of-lease-cleaning"><button className='p-description end-lease-link'>LIVE BOOKING AND PRICE</button></Link>
            </div>

            <WorkSlidesRevGen/>
            <EndLeaseTwo/>
            <div className="tik-mar"></div>
           <Tiktok/>
            <ServiceInfoSectionEnd/>

            <div className="review-bottom steam-rating rate-mar-steam">
            <div className="review-content-bottom">
            <IoMdStar size={30} color="#ffcd00" />
            <IoMdStar size={30} color="#ffcd00" />
            <IoMdStar size={30} color="#ffcd00" />
            <IoMdStar size={30} color="#ffcd00" />
            <IoMdStar size={30} color="#ffcd00" />
            <img className="gimage" src={icon} alt="logo for customer rating"/>
           </div>

           </div>
           <ReviewEnd/>
             <div className="blurb-wrap">
            <Endofleaseblurb/>
          
            </div>
            <EndLeaseThree/>
           
            <div className="review-review-steam">
            
            <EndOfLeaseCleaningPrices/>

             <div className="steam-booking-homepage top-three book-price">
              <h2>BOOK NOW 10% DICOUNT</h2>
            <Link style={{textDecoration: 'none', color: 'inherit', }}  to="/end-of-lease-cleaning"><button className='p-description end-lease-link'>LIVE BOOKING AND PRICE</button></Link>
            </div>

            <EndLeaseFour/>
            <div className="tik-con"></div>
            

            <FAQ/>
            
            



            <div className='info-steam-sec'>
            <ServiceInfoSection/>
            </div>

            <div className="steam-booking-homepage mar-buttom-steam">
            <Link style={{textDecoration: 'none', color: 'inherit', }}  to="/end-of-lease-cleaning"><button className='p-description end-lease-link'>LIVE BOOKING AND PRICE</button></Link>
            </div>

            

            <div className="review-bottom steam-rating rate-mar-steam">
            <div className="review-content-bottom">
            <IoMdStar size={30} color="#ffcd00" />
            <IoMdStar size={30} color="#ffcd00" />
            <IoMdStar size={30} color="#ffcd00" />
            <IoMdStar size={30} color="#ffcd00" />
            <IoMdStar size={30} color="#ffcd00" />
            <img className="gimage" src={icon} alt="logo for customer rating"/>
           </div>

           </div>
           <Location/>

           

           
            </div>
            
            <Profile/>

            <div className="foot"><Footer/></div> 
       
    </div>
  );
};

export default EndOfLeaseCleanMelb;
